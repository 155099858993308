.ihero-div {
  border-radius: 20px;
  background-color: #135bcd;
  color: #fff;
  padding: 60px;
  max-height: 500px;
}

.ihero-text {
  font-size: 65px;
  font-weight: 600;
  line-height: 70.52px;
  letter-spacing: -0.01em;
  text-align: left;
}
.ihero-des {
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.hero-button-div {
  margin-bottom: 0;
}
.iflex-end {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
}
.app-logo-appstore {
  margin-left: 20px;
}

.app-logo-appstore,
.app-logo-playstore,
.phn {
  margin-top: 5px;
  margin-bottom: 23px;
}

.btn-extra-large {
  font-size: 1.5rem;
  width: 300px;
  height: 80px;
  padding: 25px;
  gap: 0px;
  border-radius: 12px;
  opacity: 0px;
}

.image-div {
  display: flex;
  height: 100%;
  align-items: center;
}

.link-btn {
  text-decoration: none;
}

.phn {
  display: block;
  height: 100%;
  max-height: 400px;
  width: 100%;
  object-fit: contain;
}

.hero-btn-des {
  font-family: "DM Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 30.04px;
  color: #3a86ff;
}

.hero-button {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;
}

.hero-ibtn {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 10px 20px;
  border-radius: 12px;
}

.down-btn {
  padding: 10px 40px;
  gap: 16px;
}

.store-img {
  width: 30px;
  height: 30px;
}
.store-text-wrap {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 6px;
}

.store-text-wrap .subtitle {
  font-size: 14px;
  color: #475467;
  font-family: "Inter";
  font-weight: 400;
  line-height: 14px;
}

.store-text-wrap .title {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  color: #3a86ff;
}

.whiteBg {
  background-color: white;
}

@media (max-width: 768px) {
  .ihero-div {
    border-radius: 20px;
    background-color: #135bcd;
    color: #fff;
    padding: 20px;
    max-height: max-content;
  }
  .hero-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-ibtn {
    display: flex;
    padding: 8px 20px;
  }

  .hero-text-md {
    width: 100% !important;
    text-align: center !important;
  }

  .hero-btn-des {
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 30.04px;
    color: #3a86ff;
  }

  .ihero-text {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    margin-top: 15px;
  }
  .ihero-des {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .down-btn {
    padding: 10px 20px;
    gap: 6px;
  }

  .store-img {
    width: 18px;
    height: 18px;
  }

  .store-text-wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1px;
  }

  .store-text-wrap .subtitle {
    font-size: 10px;
    color: #475467;
    font-family: "Inter";
    font-weight: 400;
    line-height: 10px;
  }

  .store-text-wrap .title {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    color: #3a86ff;
  }

  .app-logo-appstore,
  .app-logo-playstore {
    width: 45%;
  }

  .image-div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .phn {
    width: 100%;
    margin-left: 0px;
  }
  .btn-extra-large {
    font-size: 16px;
    width: 100%;
    height: 54px;
    padding: 13px;
    gap: 0px;
    border-radius: 12px;
    opacity: 0px;
  }

  .btn-des {
    font-family: "DM Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 26.04px;
    background-color: white;
    color: #3a86ff;
  }
}

@media (max-width: 400px) {
  .down-btn {
    padding: 8px 10px;
    gap: 1px;
  }

  .store-img {
    width: 18px;
    height: 18px;
  }

  .store-text-wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1px;
  }

  .store-text-wrap .subtitle {
    font-size: 10px;
    color: #475467;
    font-family: "Inter";
    font-weight: 400;
    line-height: 10px;
  }

  .store-text-wrap .title {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 500;
    color: #3a86ff;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .ihero-div {
    border-radius: 20px;
    padding: 30px;
    max-height: 500px;
  }
  .ibtn {
    padding: 5px;
    background-color: white;
    width: 280px;
  }
  .ihero-div {
    position: relative;
    border-radius: 20px;
    background-color: #135bcd;
    color: #fff;
    padding: 5% 4.6% 0px 4.3%;
  }
  .ihero-text {
    font-size: 28px;
    font-weight: 600;
    line-height: 43.52px;
    letter-spacing: -0.01em;
    text-align: left;
    width: calc(100vw / 2.5);
  }
  .ihero-des {
    font-size: 16px;
    width: calc(100vw / 2.5);
    font-weight: 400;
    line-height: 26px;
    text-align: left;
  }

  .hero-button-div {
    margin-bottom: 0;
  }
  .phn {
    width: 100%;
    margin-left: 0px;
    object-fit: cover;
  }

  .hero-button {
    width: 100%;
    margin-bottom: 30px;
  }

  .down-btn {
    padding: 10px 16px;
    gap: 6px;
  }

  .store-img {
    width: 24px;
    height: 24px;
  }
  .store-text-wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1px;
  }

  .store-text-wrap .subtitle {
    font-size: 10px;
    color: #475467;
    font-family: "Inter";
    font-weight: 400;
    line-height: 14px;
  }

  .store-text-wrap .title {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    color: #3a86ff;
  }

  .hero-ibtn {
    display: flex;
  }
  .hero-btn-des {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 30.04px;
    color: #3a86ff;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .ihero-div {
    border-radius: 20px;
    padding: 30px;
    max-height: 500px;
  }
  .ihero-text {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-top: 15px;
  }
  .ihero-des {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
  }

  .hero-button {
    width: 100%;
    margin-bottom: 0px;
  }

  .hero-ibtn {
    display: flex;
  }

  .app-logo-appstore,
  .app-logo-playstore {
    width: 45%;
  }

  .down-btn {
    padding: 10px 30px;
    gap: 6px;
  }

  .phn {
    width: 100%;
    margin-left: 0px;
  }
  .btn-extra-large {
    font-size: 16px;
    width: 100%;
    height: 54px;
    padding: 13px;
    gap: 0px;
    border-radius: 12px;
    opacity: 0px;
  }

  .hero-btn-des {
    font-family: "DM Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 26.04px;
    background-color: white;
    color: #3a86ff;
  }
}
